import React, { useEffect, useRef, useState } from 'react';
import { Navigate } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';
// import 'tinymce/tinymce'; // TinyMCE core
// import 'tinymce/icons/default'; // Icon mặc định
// import 'tinymce/themes/silver'; // Giao diện Silver
// import 'tinymce/plugins/undo'; // Plugin undo
// import 'tinymce/plugins/redo'; // Plugin redo
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { Card, CardActions, CardContent, CardHeader, Container, Divider, Grid, MenuItem, Button, Select, TextareaAutosize, TextField } from '@mui/material';
import { Textarea } from 'components/Textarea';
import Upload from 'components/Upload';
import { DateTimePicker } from '@mui/x-date-pickers';
import Stack from '@mui/joy/Stack';
// import Button from '@mui/joy/Button';

import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import dayjs from 'dayjs';
import ArticleServices from 'services/ArticleServices';
import UploadServices from 'services/UploadServices';
import { useSearchParams } from "react-router-dom";
import config from 'config/config';
import { LoadingButton } from '@mui/lab';
import { format } from 'date-fns';

const Add = () => {
  const [searchParams] = useSearchParams();
  const type: string = searchParams.get("type") || 'article'; // Lấy giá trị của tham số 'type': articles | category
  const id: number = parseInt(searchParams.get("id")) || 0; // Lấy giá trị của tham số 'type'
  const [loading, setLoading] = useState(true);

  // const [type, setType] = useState('articles');
  const [title, setTitle] = useState('');
  const [categories, setCategories] = useState(null);
  const [category_id, setCategory] = useState(0);
  const [image, setImage] = useState('');
  const [imageData, setImageData] = useState(null);
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState('');
  const [posting_date, setPostingDate] = useState(dayjs());
  const editorRef = useRef(null);

  const [response, setResponse] = useState({ status: 0, data: [], message: '' });
  useEffect(() => {
    getAllCategory();

    if (id > 0) {
      get();
    } else {
      setLoading(false)
    }

    return () => {
      setTitle('');
      setCategory(0);
    };
  }, []);

  const handleTitleChange = (content: string) => {
    setTitle(content);
  };
  const handleCategoryChange = (category_id: any) => {
    setCategory(parseInt(category_id));
  };
  const handleImageChange = (content: string) => {
    setImage(content);
  };
  const handleImageDataChange = (content: any) => {
    setImageData(content);
  };
  const handleSummaryChange = (content: string) => {
    setSummary(content);
  };
  const handleTagsChange = (event: any) => {
    setTags(event.target.value);
  };
  const handleEditorChange = async (content: string, editor: any) => {
    setContent(content);
  };
  const handlePostingChange = (content: any) => {
    // console.log('event:', content);
    setPostingDate(content);
  };

  const handleUpload = async () => {
    if (!imageData) {
      console.log("Please select a file first.");
      return;
    }

    const img = await UploadServices.add(imageData);
    if (img.filePath) {
      setImage(img.filePath);
      return img.filePath;
    } else {
      console.log(img)
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    var img = await handleUpload();
    // Thêm logic để lưu nội dung bài viết
    var user: any = JSON.parse(localStorage.getItem('user'));
    // return console.log(image);
    if (editorRef.current) {
      await handleEditorChange(editorRef.current.getContent(), editorRef.current);
    }
    // return console.log(content);
    var res = null
    if (!id)
      res = await ArticleServices.add({
        name: title,
        parent_id: category_id,
        image: img,
        summary: summary,
        description: editorRef.current.getContent(),
        type: type,
        // tags: tags,
        posting_date: posting_date.toString(),
        user_id: user.id
      });
    else
      res = await ArticleServices.edit({
        name: title,
        parent_id: category_id,
        image: img,
        summary: summary,
        description: editorRef.current.getContent(),
        // tags: tags,
        posting_date: posting_date.toString(),
        id: id
      });
    if (res?.status == 1) return setResponse(res);
  };


  const get = async () => {
    // console.log("Get detail conent.");
    var articles = await ArticleServices.getById(id);
    if (articles) {
      articles = articles[0]
      setTitle(articles.name)
      setCategory(articles.parent_id);
      setImage(articles.image)
      setSummary(articles.summary)
      setContent(articles.description)
      // setTags(articles.data.tags)
      setPostingDate(dayjs(format(new Date(articles.posting_date * 1000), 'MM/dd/yyyy H:m a'))) //01/21/1970 09:26 AM
    }
    // console.log(articles.posting_date)
    setLoading(false)
  }
  const getAllCategory = async () => {
    const list = await ArticleServices.get({
      searchParams: { type: 'category' },
      orderBy: 'parent_id ASC, id DESC',
      isDesc: true,
      limit: 200,
      off: 0
    });
    // console.log(list)
    if (list.data) setCategories(list.data);
  };
  // Hàm giả lập để mở thư viện ảnh
  const openImageLibrary = () => {
    return new Promise((resolve) => {
      // Giả sử bạn mở cửa sổ thư viện ảnh
      const imageUrl = prompt('Select an image URL from your library');
      resolve(imageUrl);
    });
  }

  if (response.status == 1) return <Navigate to="/management/articles" />;
  return (
    <div>
      <Helmet>
        <title>Soạn thảo nội dung</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <form onSubmit={handleSubmit}>
        {loading ? <LoadingButton loading loadingPosition="start" /> :
          <Container maxWidth="lg">
            <Card>
              <CardHeader title="Soạn nội dung" />
              <Divider />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={3}
                >
                  <Grid item container>
                    <Grid item container
                      xs={12}
                      component="form"
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        required
                        id="title"
                        label="Tiêu đề"
                        placeholder='Nhập tiêu đề bài viết'
                        // defaultValue="Nhập tiêu đề bài viết"
                        value={title}
                        variant="standard"
                        fullWidth={true}
                        type='text'
                        sx={{ '& .MuiInputBase-root::before': { borderColor: '#eeeeee !important' } }}
                        onChange={(event) => handleTitleChange(event.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      {categories && <TextField
                        // required
                        id="category"
                        select
                        label="Danh muc"
                        value={category_id}
                        onChange={(event) => handleCategoryChange(event.target.value)}
                        helperText="Hãy chọn danh mục"
                        variant="standard"
                        fullWidth={true}
                        sx={{
                          '& .MuiInputBase-root::before': { borderColor: '#eeeeee !important' }
                        }}
                      >
                        {categories && categories.map((option: any) => (
                          <MenuItem key={option.id} value={option.id} >
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      }
                    </Grid>
                    <Grid item xs={6}>
                      <Upload
                        onImageFileChange={handleImageDataChange}
                        img={`${config.thumbUrl}/${image}`}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>

                    {/* <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                      <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
                      <Input
                        id="standard-adornment-amount"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      />
                    </FormControl> */}
                    {/* <TextareaAutosize aria-label="empty textarea" placeholder="Empty"/> */}
                    <Textarea id="summary" placeholder="Nhập mô tả bài viết" onEditorChange={handleSummaryChange} value={summary} />
                  </Grid>

                  <Grid item xs={12}>
                    <Editor
                      apiKey="91pqcdx4mmbq2xvluwnyvicgyavpcbi727lwnda5lyk3yqpc"
                      onInit={(_evt, editor) => editorRef.current = editor}
                      initialValue={content}
                      init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                          // Core editing features
                          'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'print', 'lists', 'media', 'searchreplace',
                          'table', 'visualblocks', 'visualchars', 'wordcount',
                          'code', 'fullscreen', 'insertdatetime', 'paste', 'help',
                          'editimage', 'preview',
                          // Your account includes a free trial of TinyMCE premium features
                          // Try the most popular premium features until Jan 21, 2025:
                          'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker',
                          'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'mentions', 'tableofcontents', 'footnotes',
                          'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown', 'importword', 'exportword', 'exportpdf'
                        ],
                        toolbar1: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image editimage media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography',
                        toolbar2: 'align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | insertdatetime | fullscreen preview save print |',
                        // toolbar:
                        //   'undo redo | formatselect | bold italic backcolor | \
                        //   alignleft aligncenter alignright alignjustify | \
                        //   bullist numlist outdent indent | removeformat | help'

                        contextmenu: 'link image table', // Menu ngữ cảnh (chuột phải)
                        branding: false, // Ẩn logo TinyMCE
                        // setup: (editor) => {
                        //   editor.on('init', () => {
                        //     editor.undoManager.clear(); // Xóa lịch sử undo cũ
                        //     editor.undoManager.add();   // Ghi trạng thái ban đầu vào lịch sử
                        //   });
                        // }
                        // base_url: '/node_modules/tinymce', // Đường dẫn thư mục TinyMCE
                        // suffix: '.min', // Sử dụng file .min.js
                        image_title: true,
                        automatic_uploads: true,
                        file_picker_types: 'image',
                        file_picker_callback: (callback, value, meta) => {
                          // Hàm callback để chọn ảnh từ thư viện hoặc upload
                          if (meta.filetype === 'image') {
                            // Mở cửa sổ chọn ảnh từ thư viện
                            openImageLibrary().then((selectedImageUrl:any) => {
                              callback(selectedImageUrl, { alt: 'Image from library' });
                            });
                          }
                        },
                      }}
                    // onEditorChange={handleEditorChange}
                    />

                  </Grid>
                  <Grid item container
                    xs={12}
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      required
                      id="tags"
                      label="Tag"
                      placeholder="Nhập danh sách tag, cách nhau bằng dấu phẩy (',')"
                      variant="standard"
                      fullWidth={true}
                      sx={{ '& .MuiInputBase-root::before': { borderColor: '#eeeeee !important' } }}
                      onChange={handleTagsChange}
                    />
                  </Grid>

                  <Grid item container>
                    <DateTimePicker defaultValue={posting_date} label="Thời gian đăng bài" name="posting_date" onChange={handlePostingChange} />
                  </Grid>
                </Grid>

              </CardContent>
              <Divider />
              <CardActions>
                <Button sx={{ margin: 1, mt: 3 }} variant='contained'
                  color="success" type="submit"
                  startIcon={<DoneIcon fontSize="small" />}>
                  Hoàn thành
                </Button>
                <Button sx={{ margin: 1, mt: 3 }} variant="contained"
                  color="secondary" type="submit"
                  startIcon={<SaveIcon fontSize="small" />}>
                  Lưu nháp
                </Button>
              </CardActions>
            </Card>
          </Container>
        }
      </form>
    </div >
  );
};

export default Add;