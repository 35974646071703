import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Divider, FormControl, Grid, Input, InputAdornment, InputLabel, MenuItem, Select, TextareaAutosize, TextField } from '@mui/material';
import { Textarea } from 'components/Textarea';
import Upload from 'components/Upload';
import { DateTimePicker, DesktopDateTimePicker, PickersTextField } from '@mui/x-date-pickers';
import Label from 'components/Label';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';

const Search = () => {
  const [title, setTitle] = useState('');
  const [category_id, setCategory] = useState(0);
  const [image, setImage] = useState('');
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState('');
  const [posting_date, setPostingDate] = useState('');

  const handleTitleChange = (title: string) => {
    setTitle(title);
  };
  const handleCategoryChange = (category_id: any) => {
    setCategory(parseInt(category_id));
  };
  const handlePostingChange = (content: string) => {
    setPostingDate(content);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Content:', content);
    // Thêm logic để lưu nội dung bài viết
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Container maxWidth="lg" sx={{mb:3}}>
          <Card>
            <CardHeader title="Tìm kiếm" />
            <Divider />
            <CardContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
              >
                <Grid item container>
                  <Grid item container
                    xs={4}
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="title"
                      label="Tiêu đề"
                      placeholder="Nhập tiêu đề bài viết"
                      variant="standard"
                      type='text'
                      sx={{ '& .MuiInputBase-root::before': { borderColor: '#eeeeee !important' } }}
                      onChange={(event) => handleTitleChange(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="category"
                      select
                      label="Danh muc"
                      placeholder="---Chọn danh mục---"
                      onChange={(event) => handleCategoryChange(event.target.value)}
                      // helperText="Hãy chọn danh mục"
                      variant="standard"
                      sx={{
                        '& .MuiInputBase-root::before': { borderColor: '#eeeeee !important' }
                      }}
                    // fullWidth={true}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                      {/* {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))} */}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <DateTimePicker label="Thời gian đăng bài" name="posting_date" />
                  </Grid>
                </Grid>
              </Grid>

            </CardContent>
            <Divider />
            <CardActions>
              <Button sx={{ margin: 1, mt: 3 }} variant="contained"
                color="info" type="submit"
                startIcon={<SearchIcon fontSize="small" />}>
                Tìm kiếm
              </Button>
              <Button sx={{ margin: 1, mt: 3 }} variant="contained"
                color="primary" type="button"
                component={RouterLink}
                // onClick={closeSidebar}
                to="/management/articles/add?type=category"
                startIcon={<AddTwoToneIcon fontSize="small" />}>
                Thêm mới
              </Button>
            </CardActions>
          </Card>
        </Container>
      </form>
    </div >
  );
};

export default Search;