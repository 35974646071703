import React, { useEffect, useState } from 'react';
import { Card, Container } from '@mui/material';
import ArticleServices from 'services/ArticleServices';
import RecentTable from './RecentTable';

const List = () => {
  const [items, setItems] = useState<any>();
  const [params, setParams] = useState<any>({
    searchParams: { type: 'category' },
    orderBy: 'parent_id ASC, id DESC',
    isDesc: true,
    limit: 200,
    off: 0
  });

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    const list = await ArticleServices.get(params);
    // console.log(list)
    if (list.data) setItems(list.data);
  };

  return (
    <div>
      <Container maxWidth="lg">
        <Card>
          <RecentTable items={items} />
        </Card>
      </Container>
    </div >
  );
};

export default List;