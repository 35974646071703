import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'components/PageTitleWrapper';
import PageHeader from './PageHeader';
import Search from './Search';
import List from './List';

const Categories = () => {

  return (
    <div>
      <Helmet>
        <title>Danh mục bài viết</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      
      <Search/>

      <List/>
      
    </div >
  );
};

export default Categories;