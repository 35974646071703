import { useState, useRef } from 'react';

import {
  Box,
  Menu,
  IconButton,
  Button,
  ListItemText,
  ListItem,
  List,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Divider
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import Modals from 'components/Modals';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArticleServices from 'services/ArticleServices';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const statusItems = [
  {
    text: 'Xóa',
    value: '-1',
    color: 'error'
  },
  {
    text: 'Ẩn',
    value: 0,
    color: 'warning'
  },
  {
    text: 'Công khai',
    value: 1,
    color: 'success'
  }
];

const ArticlesActions = (props: any) => {
  const [open, setOpen] = useState(false);
  const [done, setDone] = useState(false);
  const [status, setStatus] = useState(props.articles.status);
  const [note, setNote] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
    setDone(false);
    // setSelectedValue(value);
  };
  const theme = useTheme();

  const update = async () => {
    var data = {
      id: props.articles.id,
      status: status
    };
    // return console.log(data)
    var up = await ArticleServices.edit(data);
    props.upstatus(status);
    // console.log(up);
    setOpen(false);
    setDone(false);
  };

  return (
    <>
      <Modals
        title={'Changer Status Order'}
        name={null}
        button={
          <IconButton
            sx={{
              '&:hover': {
                background: theme.colors.primary.lighter
              },
              color: theme.palette.primary.main
            }}
            onClick={handleClickOpen}
            color="inherit"
            size="small"
          >
            <EditTwoToneIcon fontSize="small" />
          </IconButton>
        }
        open={open}
        onClose={handleClose}
        onDone={update}
      >
        <FormControl component="fieldset">
          <FormLabel component="legend">Trạng thái</FormLabel>
          <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
            {statusItems.map((ele: any, idx: number) => (
              <FormControlLabel
                key={idx}
                value={ele.value}
                control={
                  <Radio color={ele.color} checked={status == ele.value} />
                }
                label={ele.text}
                color={ele.color}
                onChange={() => setStatus(ele.value)}
                // color="success"
              />
            ))}
          </RadioGroup>
          {/* <TextField
            id="outlined-helperText"
            label="Note"
            multiline
            rows={4}
            placeholder="Input note payment"
            // defaultValue="Default Value"
            helperText="Input note payment"
            variant="standard"
            value={note}
            onChange={(v) => setNote(v.target.value)}
          />
          <p style={{ margin: '10px 0' }} />

          <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Upload file bin
            <VisuallyHiddenInput type="file" />
          </Button> */}
        </FormControl>
      </Modals>
    </>
  );
};

export default ArticlesActions;
