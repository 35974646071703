import { FC, ChangeEvent, useState } from 'react';
import { format } from 'date-fns';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader
} from '@mui/material';

import Label from 'components/Label';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import BulkActions from './BulkActions';
import Article, { ArticleStatus, ArticleType } from 'models/Article';
import ArticlesActions from '../Articles/ArticlesActions';

interface RecentTableProps {
  className?: string;
  items: Article[];
}

interface Filters {
  status?: ArticleStatus;
}

const getStatusLabel = (ItemStatus: ArticleStatus): JSX.Element => {
  const map = {
    '-1': {
      text: 'Delete',
      color: 'error'
    },
    1: {
      text: 'Active',
      color: 'success'
    },
    0: {
      text: 'Pending',
      color: 'warning'
    }
  };

  const { text, color }: any = map[ItemStatus];

  return <Label color={color}>{text}</Label>;
};

const applyFilters = (items: Article[], filters: Filters): Article[] => {
  // return null;
  return items.filter((userOrder) => {
    let matches = true;

    if (filters.status && userOrder.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (
  items: Article[],
  page: number,
  limit: number
): Article[] => {
  return items ? items.slice(page * limit, page * limit + limit) : null;
};

const RecentTable: FC<RecentTableProps> = ({ items }) => {
  const [selectedusers, setSelectedusers] = useState<number[]>([]);
  const selectedBulkActions = selectedusers.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  const statusOptions = [
    {
      id: 'all',
      name: 'All'
    },
    {
      id: 'completed',
      name: 'Completed'
    },
    {
      id: 'pending',
      name: 'Pending'
    },
    {
      id: 'failed',
      name: 'Failed'
    }
  ];

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleSelectAllusers = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedusers(
      event.target.checked
        ? items
          ? items.map((userOrder) => userOrder.id)
          : null
        : []
    );
  };

  const handleSelectOneuserOrder = (
    event: ChangeEvent<HTMLInputElement>,
    userOrderId: number
  ): void => {
    if (!selectedusers.includes(userOrderId)) {
      setSelectedusers((prevSelected) => [...prevSelected, userOrderId]);
    } else {
      setSelectedusers((prevSelected) =>
        prevSelected.filter((id) => id !== userOrderId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredusers = applyFilters(items, filters);
  const paginatedusers = applyPagination(filteredusers, page, limit);
  const selectedSomeusers =
    selectedusers.length > 0 && selectedusers.length < items.length;
  const selectedAllusers = selectedusers.length === items.length;
  const theme = useTheme();
  console.log(paginatedusers)
  return (
    <Card>
      {selectedBulkActions && (
        <Box flex={1} p={2}>
          <BulkActions />
        </Box>
      )}
      {!selectedBulkActions && (
        <CardHeader
          action={
            <Box width={150}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status || 'all'}
                  onChange={handleStatusChange}
                  label="Status"
                  autoWidth
                >
                  {statusOptions &&
                    statusOptions.map((statusOption) => (
                      <MenuItem key={statusOption.id} value={statusOption.id}>
                        {statusOption.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          }
          title="Danh sách"
        />
      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={selectedAllusers}
                  indeterminate={selectedSomeusers}
                  onChange={handleSelectAllusers}
                />
              </TableCell>
              <TableCell>Tên</TableCell>
              <TableCell>Danh mục</TableCell>
              <TableCell>Lượt xem</TableCell>
              <TableCell align="right">Ngày tạo</TableCell>
              <TableCell align="right">Trạng thái</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedusers &&
              paginatedusers.map((itemsOrder) => {
                const isuserselected = selectedusers.includes(itemsOrder.id);
                return (
                  <TableRow hover key={itemsOrder.id} selected={isuserselected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isuserselected}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleSelectOneuserOrder(event, itemsOrder.id)
                        }
                        value={isuserselected}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {itemsOrder.parent_id > 0 && '- '}
                        {itemsOrder.name}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {/* {itemsOrder.parent_id} */}
                        {paginatedusers && paginatedusers.find((cat: any) => cat.id === itemsOrder.parent_id)?.name || '-'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {itemsOrder.type}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {format(new Date(itemsOrder.create_date * 1000), 'dd/MM/yyyy')}
                        {/* {userOrder.cryptoCurrency} */}
                      </Typography>
                      {/* <Typography variant="body2" color="text.secondary" noWrap>
                      {numeral(userOrder.amount).format(
                        `${userOrder.currency}0,0.00`
                      )}
                    </Typography> */}
                    </TableCell>
                    <TableCell align="right">
                      {getStatusLabel(itemsOrder.status)}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Edit Order" arrow>
                        <ArticlesActions
                          articles={itemsOrder}
                          upstatus={(status: any) => {
                            itemsOrder.status = status;
                            // Cập nhật trạng thái trong items
                            const updatedItems = items.map((item) => {
                              if (item.id === itemsOrder.id) {
                                item.status = status; // Cập nhật trạng thái
                              }
                              return item;
                            });
                            // return setItems(updatedItems);
                            return  window.location.reload();
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Delete Order" arrow>
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.error.lighter
                            },
                            color: theme.palette.error.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredusers ? filteredusers.length : 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

RecentTable.propTypes = {
  items: PropTypes.array.isRequired
};

RecentTable.defaultProps = {
  items: []
};

export default RecentTable;
