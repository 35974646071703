import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { Box, Button, Divider, TextField } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface UploadProps {
  img?: string;
  className?: string;
  color?:
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'success'
  | 'info'
  | 'black';
  flex?: boolean;
  onImageChange?: (url: string | null) => void;
  onImageFileChange?: (file: File | null) => void;
}
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Upload: FC<UploadProps> = ({
  img,
  className,
  color = 'secondary',
  flex,
  onImageChange,
  onImageFileChange,
  ...rest
}) => {
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    // console.log(img)
    if (img) {
      setImageUrl(img);
    }
    return;
  }, []);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
      setImageUrl(URL.createObjectURL(file));
      if (onImageChange) onImageChange(URL.createObjectURL(file)); // Gửi imageUrl lên cha
      if (onImageFileChange) onImageFileChange(file); // Gửi file image lên cha
    }
  };

  return (
    <Box component="form" sx={{ mt: 3, mb: 3, border: '2px dotted #ccc', borderRadius: '8px', p: 2 }} {...rest}>
      {/* <div color="primary">
        <TextField
          type="file"
          inputProps={{ accept: 'image/*' }}
          onChange={handleImageChange}
        />
      </div> */}

      <Button
        component="label"
        role={undefined}
        variant="text"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Upload files
        <VisuallyHiddenInput
          type="file"
          onChange={handleImageChange}
          multiple
        />
      </Button>
      {imageUrl && (
        <div><Divider />
          <Box mt={2}>
            <img src={imageUrl} alt="Preview" style={{ maxWidth: '100%' }} />
          </Box>
        </div>
      )}
    </Box>
  );
};

export default Upload;
