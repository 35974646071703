// import { configs } from "../config";
import config from 'config/config';
import XhrHttps from './XhrHttps';

class UploadServices {
  domain = config.domain + 'images';

  add = async (image: any) => {
    let url = `${this.domain}/upload`;
    const formData = new FormData();
    formData.append("image", image);

    try {
      // const response = await axios.post("http://localhost:5000/upload", formData, {
      //   ,
      // });
      await XhrHttps.init(false, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }
      );
      const response = await XhrHttps.instance.post(url, formData);
      return response;

      // console.log(response.data.message);
    } catch (error) {
      console.log("Upload failed. Please try again.");
    }
  };

  get = async (data: any = null) => {
    let url = `${this.domain}/search`;
    // await XhrHttps.init(false, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'X-Custom-Header, Upgrade-Insecure-Requests'} });
    await XhrHttps.init();
    // console.log(data  )
    let items = await XhrHttps.instance.post(url, data);
    return items;
  };
}
export default new UploadServices();
