import * as React from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { TextFieldVariants } from '@mui/material';

interface TextProps {
  id?: string;
  className?: string;
  placeholder?: string;
  flex?: boolean;
  value?: string;
  children?: React.ReactNode;
  onEditorChange?: any;
}

export const Textarea: React.FC<TextProps> = ({
  id,
  placeholder = 'Empty',
  children,
  value,
  ...rest
}) => {
  const [content, onEditorChange] = React.useState(value);

  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    height:100px;
    padding: 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    /* firefox */
    &:focus-visible {
      outline: 0;
    }
  `,
  );

  React.useEffect(() => {
    if (value) {
      onEditorChange(value);
    }
    return;
  }, []);

  const onSetContent = (event: any) => {
    onEditorChange(event.target.value);
    rest.onEditorChange(event.target.value);
  }

  return <Textarea defaultValue={content ? content : value} aria-label="empty textarea" placeholder={placeholder} id={id} {...rest} minRows="5" onBlur={onSetContent} />;
}