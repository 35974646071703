import PropTypes from 'prop-types';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

import PageTitle from 'components/PageTitle';
import PageTitleWrapper from 'components/PageTitleWrapper';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import Button from '@mui/material/Button';

export const SimpleDialog: FC<PropsWithChildren<any>> = (props: any) => {
  const { title, onDone, onClose, selectedValue, open, children } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };
  const handleDone = () => {
    onDone(selectedValue);
  };

  const handleListItemClick = (value: any) => {
    onClose(value);
  };

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        open={open}
        // PaperProps={{
        //   component: 'form',
        //   onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
        //     event.preventDefault();
        //     const formData = new FormData(event.currentTarget);
        //     const formJson = Object.fromEntries((formData as any).entries());
        //     const email = formJson.email;
        //     console.log(email);
        //     handleClose();
        //   },
        // }}
      >
        <DialogTitle style={{ fontWeight: 700, fontSize: 24 }}>
          {title || ' '}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handleDone}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

SimpleDialog.propTypes = { onClose: PropTypes.func.isRequired, open: PropTypes.bool.isRequired, selectedValue: PropTypes.string.isRequired };

const Modals = (props: any) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  // setOpen(props.open)
  useEffect(() => {
    setOpen(props.open);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: any) => {
    setOpen(false);
    setSelectedValue(value);
    props.onClose();
  };
  const handleDone = (value: any) => {
    props.onDone(value);
    setOpen(false);
    setSelectedValue(value);
    props.onClose();
  };

  if (props.open != open && props.button) setOpen(props.open);

  return (
    <>
      {props.name && (
        <Button variant="contained" onClick={handleClickOpen} sx={{ mt: { xs: 2, md: 0 } }} startIcon={props.startIcon} >
          {props.name}
        </Button>
      )}
      {props.button || null}
      <SimpleDialog
        title={props.title}
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        onDone={handleDone}
      >
        {props?.children}
      </SimpleDialog>
    </>
  );
};

export default Modals;
