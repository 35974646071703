import { Typography, Button, Grid } from '@mui/material';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useState } from 'react';
import Modals, { SimpleDialog } from 'components/Modals';
// import FormCreateNewItem from './FormCreateNewItem';

function PageHeader() {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [done, setDone] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: any) => {
    setOpen(false);
    setSelectedValue(value);
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Quản lý nội dung
        </Typography>
        <Typography variant="subtitle2">Danh sách bài viết</Typography>
      </Grid>
      <Grid item>
        <Modals
          title={'Tạo bài mới'}
          selectedValue={selectedValue}
          startIcon={<AddTwoToneIcon fontSize="small" />}
          open={open}
          onClose={handleClose}
          onDone={(selectedValue) => setDone(true)}
        >
          {/* <FormCreateNewItem isDone={done} setDone={() => setDone(false)} /> */}
        </Modals>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
