// import { configs } from "../config";
import config from 'config/config';
import XhrHttps from './XhrHttps';

class ArticleServices {
  domain = config.domain + 'article';

  add = async (data: any) => {
    let url = `${this.domain}/add`;
    // console.log('---Regis---');
    // console.log(data)
    await XhrHttps.init();
    let item = await XhrHttps.instance.post(url, data);
    return item;
  };

  edit = async (data: any) => {
    let url = `${this.domain}/edit`;
    await XhrHttps.init();
    let item = await XhrHttps.instance.post(url, data);
    return item;
  };

  get = async (data: any = null) => {
    let url = `${this.domain}/`;
    // await XhrHttps.init(false, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'X-Custom-Header, Upgrade-Insecure-Requests'} });
    await XhrHttps.init();

    let items = await XhrHttps.instance.get(url, { params: data });
    return items;
  };

  getById = async (id: any = null) => {
    let url = `${this.domain}/:id`;
    await XhrHttps.init();
    // console.log(data  )
    let items = await XhrHttps.instance.get(url, { params: { id: id } });
    return items;
  };
}
export default new ArticleServices();
